<template>
  <div>
    <PageHeader />
    <b-tabs>
      <b-tab :title="$t('cashback.settings')" active>
        <component :is="selectedComponent" />
      </b-tab>
      <b-tab :title="$t('cashback.history')">
        <CashbackTransaction />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  page: {
    title: 'Cashback Promotions',
  },
  components: {
    CashbackMaster: () => import('../../../components/cashback/master'),
    CashbackAgent: () => import('../../../components/cashback/agent'),
    CashbackTransaction: () => import('./transaction'),
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['isMaster', 'isOwner']),
    isDisplayAll() {
      return !!(this.isOwner || this.isMaster)
    },
    selectedComponent() {
      return this.isDisplayAll ? 'CashbackMaster' : 'CashbackAgent'
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['fetchCashbackSettings']),
    fetchData() {
      this.fetchCashbackSettings()
    },
  },
}
</script>
